
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="pageType==='list'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('list')">空间列表</div>
          <div v-if="pageType==='detail'" :class="pageType==='detail'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('detail')">空间详情</div>
        </div>

        <component :is="pageType + 'Component'" ref="bottom"  />
    </div>
</template>

<script>
export default {
  components: {
    listComponent: (resolve) => {
      require(['@/components/rentalSpace/List'], resolve)
    },
    detailComponent: (resolve) => {
      require(['@/components/rentalSpace/modalComponents/detail'], resolve)
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    handleChangeTag (val) {
      this.$store.commit('set_space_pageType', val)
    }

  },
  computed: {
    pageType () {
      return this.$store.state.rentalSpace.pageType
    }
  },
  watch: {
  }
}
</script>
